<template>
  <div id="radar_fist">
    <!-- 选择后的状态 -->
    <div class="box_frame" :class="$store.state.lang == 'en' ? 'english' : ''">
      <!-- 展开盒子内容 -->
      <div class="open_button" v-show="is_open" @click="change_open" :class="$store.state.lang == 'en' ? 'changeBGimg' : ''"></div>
      <!-- 内容盒子 -->
      <div class="content_box" v-show="is_null">
        <!-- 头部盒子 -->
        <div class="header_title">
          <!--选择盒子 -->
          <div class="left_click_box" v-show="attributeAllList != '' && $store.state.lang == 'cn'">
            <div class="left_choose_img">
              <img src="https://newstore.vynior.com/choose.png" alt="" />
              <!-- 属性的盒子 -->
              <el-cascader
                class="img-cascader"
                :options="attributeAllList"
                :props="{ multiple: true, emitPath: true }"
                :value="values"
                ref="cascader"
                @change="cascaderChange"></el-cascader>
            </div>
            <!-- 重置 -->
            <div class="left_reset_img" @click="Reset">
              <img src="https://newstore.vynior.com/czsscz.png" alt="" />
            </div>
          </div>

          <!--kong选择盒子 -->
          <div class="left_click_box" v-show="attributeAllList == '' && $store.state.lang == 'cn'">
            <!-- 选择 -->
            <div class="left_choose_img">
              <img src="https://newstore.vynior.com/gray_choose.png" alt="" />
            </div>

            <!-- 重置 -->
            <div class="left_reset_img" @click="Reset">
              <img src="https://newstore.vynior.com/czsscz.png" alt="" />
            </div>
          </div>
          <!--kong选择盒子 -->
          <div class="left_click_box" v-show="attributeAllList == '' && $store.state.lang == 'en'">
            <!-- 选择 -->
            <div class="left_choose_img">
              <img src="https://newstore.vynior.com/gray_choose.png" alt="" v-show="$store.state.lang == 'cn'" />
              <img src="https://newstore.vynior.com/grayXZ.png" alt="" v-show="$store.state.lang == 'en'" />
            </div>

            <!-- 重置 -->
            <div class="left_reset_img" @click="Reset">
              <img src="https://newstore.vynior.com/GroupReset.png" alt="" />
            </div>
          </div>
          <!-- 选择english -->
          <div class="left_click_box" v-show="attributeAllList != '' && $store.state.lang == 'en'">
            <div class="left_choose_img">
              <img src="https://newstore.vynior.com/xuanzechoo.png" alt="" />
              <!-- 属性的盒子 -->
              <el-cascader
                class="img-cascader"
                :options="attributeAllList"
                :props="{ multiple: true, emitPath: true }"
                :value="values"
                @change="cascaderChange"></el-cascader>
            </div>
            <!-- 重置 -->
            <div class="left_reset_img" @click="Reset">
              <img src="https://newstore.vynior.com/GroupReset.png" alt="" />
            </div>
          </div>

          <!-- 产品名称盒子 -->
          <div class="right_name_box" v-show="show_productName.length != 0">
            <div class="name" v-for="(item, index) in show_productName" :key="index">
              <div class="name_box">
                <el-tooltip :open-delay="500" class="item" effect="dark" placement="top" style="z-index: 100" popper-class="back_ground">
                  <div slot="content" style="line-height: 18px">
                    <p>{{ item.program }}</p>
                    <p>{{ item.product }}</p>
                  </div>
                  <p>
                    {{ item.program }} <br />
                    {{ item.product }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </div>
          <!--kong 名称盒子 -->
          <div class="right_name_boxs" v-show="show_productName.length == 0">
            <div class="name1">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="names">-- --</div>
            <div class="nameed">-- --</div>
          </div>
          <!-- open/close按钮 -->
          <div class="close_button" @click="change_show"></div>
        </div>

        <!-- data盒子 -->
        <div class="data_bycical">
          <!-- 属性名称展示 -->
          <div class="data_attribute" v-show="attributeName.length != 0">
            <div class="attribute_data_name" v-for="(items, indexs) in attributeName" :key="indexs">
              {{ items }}
            </div>
          </div>
          <!-- kong属性名称展示 -->
          <div class="data_attributes" v-show="attributeName.length == 0">
            <div class="attribute_data_name">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="data_names">--</div>
            <div class="attribute_data_named">--</div>
          </div>
          <!-- 数据展示 -->
          <div class="data_num" v-show="show_productName.length != 0">
            <div class="attribute_data_num" v-for="(item, index) in show_productData" :key="index">
              <div class="attribute_data_nums" v-for="(ite, ind) in item.panel_attributes_data" :key="ind">
                <div class="items">
                  {{ ite }}
                </div>
              </div>
            </div>
          </div>

          <!-- 空数据展示 -->
          <div class="data_nums" v-show="show_productName.length == 0">
            <img src="https://newstore.vynior.com/null.png" alt="" />
            <span>{{ $t(`message.History.NoData`) }}</span>
          </div>
        </div>
      </div>
      <!-- 雷达图 -->
      <div
        class="box_data_yc_radar radar_position"
        :class="is_open == 1 ? 'box_data_yc_radars' : 'box_data_yc_radarss'"
        v-show="show_productName.length != 0">
        <div id="myRadar" class="echart"></div>

        <div class="chart-legend">
          <div class="chart-legend-f">
            <div
              class="chart-legend-for"
              v-show="show_radars_data[index].name != undefined && index >= 4"
              v-for="(item, index) in show_productName"
              :key="index">
              <div class="legend-for-back" :style="{ 'background-color': colorArr[index] }"></div>
              <div class="legend-for-text">
                <el-tooltip
                  :open-delay="500"
                  class="item"
                  effect="dark"
                  :visible-arrow="false"
                  :content="''"
                  placement="top"
                  popper-class="back_ground">
                  <div slot="content">{{ item.program }}<br />{{ item.product }}</div>
                  <el-button>
                    {{ item.program }}<br />
                    {{ item.product }}
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="chart-legend-f">
            <div
              class="chart-legend-for"
              v-show="show_radars_data[index].name != undefined && index < 4"
              v-for="(item, index) in show_productName"
              :key="index">
              <div class="legend-for-back" :style="{ 'background-color': colorArr[index] }"></div>
              <div class="legend-for-text">
                <el-tooltip
                  class="item"
                  :open-delay="500"
                  effect="dark"
                  :visible-arrow="false"
                  :content="''"
                  placement="top"
                  popper-class="back_ground">
                  <div slot="content">{{ item.program }}<br />{{ item.product }}</div>
                  <el-button>
                    {{ item.program }}<br />
                    {{ item.product }}
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 空雷达图 -->
      <div class="box_none_yc_radar" :class="is_radars == 1 ? 'add_left' : ''" v-show="show_productName.length == 0">
        <img src="https://newstore.vynior.com/null2.png" alt="" />
        <span> {{ $t(`message.History.empty`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
let chartDom;
let myCharts;
import { message } from '@/utils/resetMessage';
export default {
  data() {
    return {
      max: 15,
      res_panelData: [], //1.1接收paneldata的数据
      maxValues: [], //对比每一项的最大值
      attributeAllList: [], //1.1完整的属性名称
      attributeName: [], //1.1展示属性名称
      show_productData: [], //1.1展示的产品数据
      show_productName: [], //1.1展示的产品名字
      radius: '45%', //雷达图放大比例
      values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], //选中的属性下标
      colorArr: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      radar_num: false, //雷达刻度
      vak: -1,
      is_radars: 0,

      mid_product_data: [
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
      ], //中转赋值 data

      show_radar_att_name: [], //展示 雷达图 属性名称
      show_radars_data: [
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
      ], //雷达图的数据
      old_radars_data: [
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
      ],
      mid_radars_data: [
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
        { value: [], name: '' },
      ],
      // open/close
      is_null: 1,
      is_open: 0,
      panel_attributes_item: [], //   感官属性名称
      showitem: false,
      indexs: 0, // 下标
    };
  },
  watch: {
    //监听 选择对比产品的名字
    '$store.state.productNameList': {
      handler(newval) {
        this.show_productName = newval.slice(0, 8);
        this.show_productName.forEach((item) => {
          this.show_product_name.push(item.product);
        });
      },
    },
    // 监听 父组件传递过来的数据
    '$store.state.panel_data': {
      handler(newval) {
        //console.log("panel_Datanewval :>> ", newval);
        this.res_panelData = newval;

        this.mid_product_data = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];

        //将数据处理为单列数组，给页面展示
        for (let i = 0; i < this.mid_product_data.length; i++) {
          for (let j = 0; j < this.res_panelData.length; j++) {
            if (this.res_panelData[j][i] != undefined) {
              this.mid_product_data[i].panel_attributes_data.push(this.res_panelData[j][i].toFixed(1));
            }
          }
        }

        this.managePaneldata();
      },
    },
    // !监听 maxValues
    // "maxValues":{
    //   handler(){
    //     this.maxValues.forEach((item,index)=>{
    //   this.maxValues[index] = Math.ceil(item)
    //     })
    //   }
    // },
    //监听 父组件传递过来的属性;
    '$store.state.panelNameList': {
      handler(newval) {
        this.attributeName = newval.slice(0, 10);
        this.show_radar_att_name = this.attributeName.map((item) => ({
          name: item,
          max: this.max,
          axisLabel: { show: false },
        }));
        this.radars();
        //console.log("newval :>> ", newval);
        if (newval != '') {
          let res = newval;
          let that = this;
          let arr = [];
          res.forEach((item, index) => {
            // //console.log(item,index,'itemindex');
            let obj = {};
            obj.label = item;
            obj.value = index;
            arr[index] = obj;
          });
          //console.log("arr :>> ", arr);
          that.attributeAllList = arr;
          //console.log("that.attributeAllList :>> ", that.attributeAllList);
        }
      },
    },
    //监听 刻度数字显示
    '$store.state.line_num': {
      handler() {
        this.show_radar_num();
      },
    },
    //监听 展示的属性名字
    attributeName: {
      handler(newval) {
        //console.log("newval :>> ", newval);
        //console.log("oldval :>> ", oldval);
        //处理雷达图的属性名称
        this.show_radar_att_name = newval.map((item) => ({
          name: item,
          max: this.max,
          axisLabel: { show: false },
        }));
        this.radars();
      },
    },
    // !切换字体
    '$store.state.lang': {
      handler() {
        // !切换重置内容
        if (this.$store.state.panelNameList.length != 0) {
          // this.attributeName=this.$store.state.panelNameList.splice(0,10)
          let res =
            this.$store.state.lang == 'cn'
              ? JSON.parse(JSON.stringify(this.$store.state.panelNameList)) || {}
              : JSON.parse(JSON.stringify(this.$store.state.panelNameList_en)) || {};
          this.attributeName = res.slice(0, 10);
          this.show_radar_att_name = this.attributeName.map((item) => ({
            name: item,
            max: this.max,
            axisLabel: { show: false },
          }));
          // this.radars();

          let arr = [];
          res.forEach((item, index) => {
            // //console.log(item,index,'itemindex');
            let obj = {};
            obj.label = item;
            obj.value = index;
            arr[index] = obj;
          });
          //console.log("arr :>> ", arr);
          this.attributeAllList = arr;
          //console.log("that.attributeAllList :>> ", this.attributeAllList);
        }
        //对比产品数据 1.1
        if (this.$store.state.panel_data.length != 0) {
          //console.log("ji进入data :>> ");
          this.res_panelData = this.$store.state.panel_data;
          //console.log( "this.$store.state.panel_data :>> ", this.$store.state.panel_data );
          this.mid_product_data = [
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
          ];

          //将数据处理为单列数组，给页面展示
          for (let i = 0; i < this.mid_product_data.length; i++) {
            for (let j = 0; j < this.res_panelData.length; j++) {
              if (this.res_panelData[j][i] != undefined) {
                this.mid_product_data[i].panel_attributes_data.push(this.res_panelData[j][i].toFixed(1));
              }
            }
          }

          // console.log('this.mid_product_data :>> ', this.mid_product_data);
          // console.log('this.res_panelData :>> ', this.res_panelData);
          let ten = [
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
            { panel_attributes_data: [] },
          ];
          let middle = JSON.parse(JSON.stringify(this.mid_product_data));
          //截取的数据10个数据长度,初始化展示data
          for (let i = 0; i < middle.length; i++) {
            ten[i].panel_attributes_data = middle[i].panel_attributes_data.splice(0, this.attributeName.length);
          }
          this.show_productData = ten;

          // 雷达图数据
          this.old_radars_data = [
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
            { value: [], name: '', max: this.max },
          ];
          for (let index = 0; index < this.show_productName.length; index++) {
            this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
            this.old_radars_data[index].name = this.show_productName[index].product;
          }
          this.show_radars_data = this.old_radars_data;
          //赋值展示雷达图数据;

          //切除雷达图多余的数组
          let deleteArr = [];
          for (let x = 0; x < 8; x++) {
            if (this.show_radars_data[x].value.length == 0) {
              //console.log(x);
              deleteArr.push(x);
            }
          }
          //console.log("object :>> ", deleteArr[0], deleteArr.at(-1));
          this.show_radars_data.splice(deleteArr[0], deleteArr[deleteArr.length - 1]);
          // this.radars();
        }
      },
    },
    // ! 监听雷达图数据
    show_radars_data: {
      handler() {
        console.log('监听到数据的改变', this.show_radars_data);
        let numArr = [];
        for (let i = 0; i < this.show_radars_data.length; i++) {
          numArr.push(this.show_radars_data[i].value);
          console.log(this.show_radars_data[i].value, '里面的数字', numArr);
          let maxNumber = parseFloat(numArr[0][0]);
          for (let subList of numArr) {
            // 循环遍历嵌套的列表
            for (let number of subList) {
              // 在子列表中找最大值
              if (!isNaN(parseFloat(number)) && parseFloat(number) > maxNumber) {
                maxNumber = parseFloat(number);
              }
            }
            if (maxNumber <= 10) {
              maxNumber = 10;
              this.max = maxNumber;
              this.radars();
            } else if (maxNumber >= 10) {
              maxNumber = 15;
              this.max = maxNumber;
              this.radars();
            }
            console.log(maxNumber);
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    //选择属性里的勾选状况; 1.1
    cascaderChange(e) {
      this.$store.commit('$_changeLine_num', false);
      console.log(e, 'eee');
      let arr1 = []; //下标;
      let arr2 = []; //名称

      e.forEach((item) => {
        arr1.push(item[0]);
        arr2.push(this.attributeAllList[item].label);
      });
      if (arr2.length <= 3) {
        if (arr2.length != 0) {
          message({
            type: 'warning',
            showClose: true,
            message: this.$t(`message.History.Min`),
          });
        }
      } else {
        this.attributeName = arr2;

        //console.log(arr1, "arr1");
        console.log(arr2, 'arr2');
        this.show_productData = [
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
          { panel_attributes_data: [] },
        ];
        //中转变量
        let transfer = JSON.parse(JSON.stringify(this.mid_product_data));
        let transfor_data = [];

        // 中转雷达图数据
        var mid_radars_data = [
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
          { value: [], name: '', max: this.max },
        ];
        //拿到全部数据(包含undefined)
        for (let j = 0; j < arr1.length; j++) {
          for (let i = 0; i < transfer.length; i++) {
            transfor_data.push(transfer[i].panel_attributes_data[arr1[j]]);
          }
        }
        //处理数据剔除undefined
        var int = 0;
        for (let index = 0; index < transfor_data.length; index++) {
          // 循环给目标数组 赋值newProducts
          if (int > 7) {
            int = 0;
          }
          mid_radars_data[int].value.push(transfor_data[index]);
          // 展示对比数据处理
          this.show_productData[int].panel_attributes_data.push(transfor_data[index]);
          int = int + 1;
        }

        //处理雷达图的属性名称
        this.show_radar_att_name = arr2.map((item) => ({
          name: item,
          max: this.max,
          axisLabel: { show: false },
        }));

        for (let x = 0; x < this.show_radars_data.length; x++) {
          this.show_radars_data[x].value = mid_radars_data[x].value;
        }

        this.radars();

        //console.log("show_radars_data :>> ", mid_radars_data);
        //console.log("this.show_radars_data :>> ", this.show_radars_data);
        //console.log("transfor_data :>> ", transfor_data);
        //console.log("this.show_productData :>> ", this.show_productData);
      }
    },
    change_open() {
      this.is_open = 0;
      this.is_null = 1;
      this.is_radars = 0;
      this.radius = '45%';
      myCharts.resize();
      this.radars(); //雷达图
    },
    // 控制数据展示
    change_show() {
      this.is_open = 1;
      this.is_null = 0;
      this.is_radars = 1;
      this.radius = '58%';
      this.radars(); //雷达图
      myCharts.resize();
    },
    //接收过来的数据处理1.1
    managePaneldata() {
      let ten = [
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
      ];
      let middle = JSON.parse(JSON.stringify(this.mid_product_data));
      //截取的数据10个数据长度,初始化展示data
      for (let i = 0; i < middle.length; i++) {
        ten[i].panel_attributes_data = middle[i].panel_attributes_data.splice(0, this.attributeName.length);
      }
      this.show_productData = ten;
      // for (let i = 0; i < this.old_radars_data[0].value.length; i++) {
      //   let maxValue = this.old_radars_data[0].value[i]; // 默认第一个为最大值
      //   for (let j = 1; j < this.old_radars_data.length; j++) {
      //     if (Number(this.old_radars_data[j].value[i]) > Number(maxValue)) { // 转换为数字并比较
      //       maxValue = this.old_radars_data[j].value[i];
      //     }
      //   }
      //  this.maxValues.push(maxValue);
      // }
      console.log(this.$msgboxmaxValues); // 输出所有 value 数组相同下标的最大值
      // 雷达图数据
      this.old_radars_data = [
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
      ];
      for (let index = 0; index < this.show_productName.length; index++) {
        this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
        this.old_radars_data[index].name = this.show_productName[index].product;
        this.old_radars_data[index].max = this.max;
      }
      // 找到最大值

      //赋值展示雷达图数据;
      this.show_radars_data = this.old_radars_data;
      //切除雷达图多余的数组
      let deleteArr = [];
      for (let x = 0; x < 8; x++) {
        if (this.show_radars_data[x].value.length == 0) {
          //console.log(x);
          deleteArr.push(x);
        }
      }
      //console.log("object :>> ", deleteArr[0], deleteArr.at(-1));
      this.show_radars_data.splice(deleteArr[0], deleteArr[deleteArr.length - 1]);
      this.radars();
    },
    // 显示雷达图刻度数字
    show_radar_num() {
      if (this.$store.state.line_num == true) {
        let num = this.show_radar_att_name.length;
        //console.log(num);
        //console.log(this.show_radar_att_name);
        //console.log(this.show_radar_att_name[0].max);
        if (this.show_radar_att_name[0].max == undefined) {
          let qwe = [];
          qwe = this.show_radar_att_name.map((item) => ({
            name: item.name,
            max: this.max,
            axisLabel: { show: false },
          }));
          this.show_radar_att_name = qwe;
          //console.log(this.show_radar_att_name);
        }
        //console.log(this.show_radar_att_name);
        this.show_radar_att_name[num - 1].axisLabel.show = true;
        //console.log(this.show_radar_att_name[num - 1]);
        this.radars();
      } else {
        let num = this.show_radar_att_name.length;
        //console.log(num);
        this.show_radar_att_name[num - 1].axisLabel.show = false;
        //console.log(this.show_radar_att_name[num - 1]);
        this.radars();
      }
    },
    // 雷达图数据
    radars() {
      var chartDom = document.getElementById('myRadar');
      var myCharts = echarts.init(chartDom);
      var options;

      options = {
        title: {},
        tooltip: {},
        // 产品名称
        legend: {
          show: false,
          origin: 'horizontal',
          data: this.show_product_name,
          top: '0%',
          right: '4%',
          width: '60',
          height: '30',
          itemWidth: 9,
          itemHeight: 9,
          itemGap: 10,
          padding: 0,
          tooltip: {
            show: true,
            position: 'left',
          },
          textStyle: {
            width: 60,
            padding: 0,
            // backgroundColor:'#C65D3E',
            borderType: 'solid',
            borderColor: '#4099A0',
            overflow: 'break',
          },
          // type:'scroll',
          // 图例文字超出换行
          formatter: function (name) {
            return name.length > 6 ? name.slice(0, 4) + '...' : name;
          },
        },
        // 数据属性名称
        radar: {
          // shape: 'circle',
          indicator: this.show_radar_att_name,

          axisName: {
            // color: "rgba(234, 33, 18, 1)",
            fontSize: 16,
            width: 200,
          },

          center: ['50%', '45%'],
          radius: this.radius,

          splitNumber: 3,

          // axisLabel: {
          //   show: true,
          // },
        },
        // tooltip: {},
        grid: {
          show: false,
          left: '0%',
          right: '0%',
          top: '0%',
          bottom: '0%',
        },
        // 文字配置

        // 数据
        series: [
          //数据
          {
            name: '数据2',
            type: 'radar',
            data: this.show_radars_data,
          },
        ],
      };

      options && myCharts.setOption(options);

      window.addEventListener('resize', () => {
        myCharts.resize();
      });
    },
    //重置按钮操作
    Reset() {
      // this.$refs['cascader'].panel.clearCheckedNodes();
      this.values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

      let res =
        this.$store.state.lang == 'cn'
          ? JSON.parse(JSON.stringify(this.$store.state.panelNameList))
          : JSON.parse(JSON.stringify(this.$store.state.panelNameList_en));
      this.attributeName = res.slice(0, 10);
      this.managePaneldata();
      this.radars();
    },
  },
  created() {},
  mounted() {
    //! 检查数据大于8  截取
    if (true) {
      if (this.$store.state.productNameList.length > 8) {
        this.$EventBus.$emit('RadarMap', 8);
        console.log('触发事件');
      }
    }
    //属性名称 1.1
    if (this.$store.state.panelNameList.length != 0) {
      // this.attributeName=this.$store.state.panelNameList.splice(0,10)
      let res =
        this.$store.state.lang == 'cn'
          ? JSON.parse(JSON.stringify(this.$store.state.panelNameList)) || {}
          : JSON.parse(JSON.stringify(this.$store.state.panelNameList_en)) || {};
      this.attributeName = res.slice(0, 10);
      this.show_radar_att_name = this.attributeName.map((item) => ({
        name: item,
        max: this.max,
        axisLabel: { show: false },
      }));
      // this.radars();

      let arr = [];
      res.forEach((item, index) => {
        // //console.log(item,index,'itemindex');
        let obj = {};
        obj.label = item;
        obj.value = index;
        arr[index] = obj;
      });
      //console.log("arr :>> ", arr);
      this.attributeAllList = arr;
      //console.log("that.attributeAllList :>> ", this.attributeAllList);
    }
    //对比产品名称 1.1
    if (this.$store.state.productNameList.length != 0) {
      this.show_productName = this.$store.state.productNameList.slice(0, 8) || {};
      this.radars();
    }
    //对比产品数据 1.1
    if (this.$store.state.panel_data.length != 0) {
      //console.log("ji进入data :>> ");
      this.res_panelData = this.$store.state.panel_data;
      //console.log( "this.$store.state.panel_data :>> ", this.$store.state.panel_data );
      this.mid_product_data = [
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
      ];

      //将数据处理为单列数组，给页面展示
      for (let i = 0; i < this.mid_product_data.length; i++) {
        for (let j = 0; j < this.res_panelData.length; j++) {
          if (this.res_panelData[j][i] != undefined) {
            this.mid_product_data[i].panel_attributes_data.push(this.res_panelData[j][i].toFixed(1));
          }
        }
      }

      // console.log('this.mid_product_data :>> ', this.mid_product_data);
      // console.log('this.res_panelData :>> ', this.res_panelData);
      let ten = [
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
        { panel_attributes_data: [] },
      ];
      let middle = JSON.parse(JSON.stringify(this.mid_product_data));
      //截取的数据10个数据长度,初始化展示data
      for (let i = 0; i < middle.length; i++) {
        ten[i].panel_attributes_data = middle[i].panel_attributes_data.splice(0, this.attributeName.length);
      }
      this.show_productData = ten;

      // 雷达图数据
      this.old_radars_data = [
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
      ];
      for (let index = 0; index < this.show_productName.length; index++) {
        this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
        this.old_radars_data[index].name = this.show_productName[index].product;
        this.old_radars_data[index].max = this.max;
      }
      this.show_radars_data = this.old_radars_data;
      //赋值展示雷达图数据;
      // !初始化数据
      //     for (let i = 0; i < this.old_radars_data[0].value.length; i++) {
      //   let maxValue = this.old_radars_data[0].value[i]; // 默认第一个为最大值
      //   for (let j = 1; j < this.old_radars_data.length; j++) {
      //     if (Number(this.old_radars_data[j].value[i]) > Number(maxValue)) { // 转换为数字并比较
      //       maxValue =Number(this.old_radars_data[j].value[i]).toFixed(2)
      //       console.log("转换值");
      //     }
      //   }
      //  this.maxValues.push(maxValue);
      // }
      //  this.maxValues.forEach((item,index)=>{
      //     this.maxValues[index] = Math.ceil(item).toFixed(2)
      //       })
      console.log(this.$msgboxmaxValues);
      // !结束
      // 雷达图数据
      this.old_radars_data = [
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
        { value: [], name: '', max: this.max },
      ];
      for (let index = 0; index < this.show_productName.length; index++) {
        this.old_radars_data[index].value = this.show_productData[index].panel_attributes_data;
        this.old_radars_data[index].name = this.show_productName[index].product;
        this.old_radars_data[index].max = this.max;
      }
      this.show_radars_data = this.old_radars_data;
      //赋值展示雷达图数据;

      //切除雷达图多余的数组
      let deleteArr = [];
      for (let x = 0; x < 8; x++) {
        if (this.show_radars_data[x].value.length == 0) {
          //console.log(x);
          deleteArr.push(x);
        }
      }
      //console.log("object :>> ", deleteArr[0], deleteArr.at(-1));
      this.show_radars_data.splice(deleteArr[0], deleteArr[deleteArr.length - 1]);
      // this.radars();
    }
    chartDom = document.getElementById('myRadar');
    myCharts = echarts.init(chartDom);
    this.radars();
  },
  destroyed() {
    this.$store.commit('$_changeLine_num', false);
  },
};
</script>

<style lang="less" scopded>
@import url('../../style/History/radar_fist.less');
</style>
